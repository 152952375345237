"use strict";

(function () {
  function init(item) {
    var items = item.querySelectorAll('li'),
        current = 0; //create nav

    var nav = document.createElement('nav');
    nav.className = 'nav_arrows'; //create button prev

    var prevbtn = document.createElement('button');
    prevbtn.className = 'prev';
    prevbtn.setAttribute('aria-label', 'Prev'); //create button next

    var nextbtn = document.createElement('button');
    nextbtn.className = 'next';
    nextbtn.setAttribute('aria-label', 'Next'); //create counter

    var counter = document.createElement('div');
    counter.className = 'counter';
    counter.innerHTML = "<span>1</span><span>" + items.length + "</span>";
    var autoplay_home_slider = false,
        home_slider_dir = 'right';

    if (items.length > 1) {
      nav.appendChild(prevbtn); // nav.appendChild(counter);

      nav.appendChild(nextbtn);
      item.appendChild(nav);
    }

    items[current].className = "current";
    if (items.length > 1) items[items.length - 1].className = "prev_slide";

    var navigate = function navigate(dir) {
      items[current].className = "";

      if (dir === 'right') {
        current = current < items.length - 1 ? current + 1 : 0;
      } else {
        current = current > 0 ? current - 1 : items.length - 1;
      }

      var nextCurrent = current < items.length - 1 ? current + 1 : 0,
          prevCurrent = current > 0 ? current - 1 : items.length - 1;
      items[current].className = "current";
      items[prevCurrent].className = "prev_slide";
      items[nextCurrent].className = ""; //update counter
      // counter.firstChild.textContent = current + 1;
    }; // setInterval(function(){
    // 	if(autoplay_home_slider){
    // 		navigate(home_slider_dir);
    // 	}
    // },5000);


    prevbtn.addEventListener('click', function () {
      navigate('left');
      autoplay_home_slider = false;
      home_slider_dir = 'left';
    });
    nextbtn.addEventListener('click', function () {
      navigate('right');
      autoplay_home_slider = false;
      home_slider_dir = 'right';
    }); //keyboard navigation

    document.addEventListener('keydown', function (ev) {
      var keyCode = ev.keyCode || ev.which;

      switch (keyCode) {
        case 37:
          navigate('left');
          break;

        case 39:
          navigate('right');
          break;
      }
    }); // swipe navigation
    // from http://stackoverflow.com/a/23230280

    item.addEventListener('touchstart', handleTouchStart, false);
    item.addEventListener('touchmove', handleTouchMove, false);
    var xDown = null;
    var yDown = null;

    function handleTouchStart(evt) {
      xDown = evt.touches[0].clientX;
      yDown = evt.touches[0].clientY;
    }

    ;

    function handleTouchMove(evt) {
      if (!xDown || !yDown) {
        return;
      }

      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;
      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        /*most significant*/
        if (xDiff > 0) {
          /* left swipe */
          navigate('right');
          autoplay_home_slider = false;
          home_slider_dir = 'right';
        } else {
          navigate('left');
          autoplay_home_slider = false;
          home_slider_dir = 'left';
        }
      }
      /* reset values */


      xDown = null;
      yDown = null;
    }

    ;
  }

  [].slice.call(document.querySelectorAll('.cd-slider')).forEach(function (item) {
    init(item);
  });
})();
/* Leadership page */
//hide others and show only clicked element


$(document).on('click', '.team-item-box', function (e) {
  e.preventDefault();
  $('.dir-team-item-box').removeClass('active');
  $('.dir-team-item-box').removeClass('img-grayscale');
  $('.dir-team-item-box').find('custom-arrow').removeClass('custom-arrow');
  $('.dir-team-item-box').find('.custom-arrow').removeClass('down').addClass('down');
  $('.item-details-box').removeClass('show');
  $('.team-item-box').find('custom-arrow').removeClass('custom-arrow');
  $('.team-item-box').removeClass('active');
  $('.team-item-box').addClass('img-grayscale');
  $('.team-item-box').find('.custom-arrow').removeClass('down').addClass('down');

  if (!$(this).hasClass('collapsed')) {
    $(this).closest('.team-item-box').removeClass('img-grayscale');
    $(this).closest('.team-item-box').addClass('active');
    $(this).find('.custom-arrow').removeClass('down').addClass('up');
  } else {
    $('.team-item-box').removeClass('img-grayscale');
  }
});
$(document).on('click', '.dir-team-item-box', function (e) {
  e.preventDefault();
  $('.team-item-box').removeClass('active');
  $('.team-item-box').removeClass('img-grayscale');
  $('.team-item-box').find('custom-arrow').removeClass('custom-arrow');
  $('.team-item-box').find('.custom-arrow').removeClass('down').addClass('down');
  $('.item-details-box').removeClass('show');
  $('.dir-team-item-box').find('custom-arrow').removeClass('custom-arrow');
  $('.dir-team-item-box').removeClass('active');
  $('.dir-team-item-box').addClass('img-grayscale');
  $('.dir-team-item-box').find('.custom-arrow').removeClass('down').addClass('down');

  if (!$(this).hasClass('collapsed')) {
    $(this).closest('.dir-team-item-box').removeClass('img-grayscale');
    $(this).closest('.dir-team-item-box').addClass('active');
    $(this).find('.custom-arrow').removeClass('down').addClass('up');
  } else {
    $('.dir-team-item-box').removeClass('img-grayscale');
  }
}); // if you click outside then collapse all
//hover effect

$(document).on('mouseover', '.card', function (e) {
  $(this).closest('.card').addClass('hover');
});
$(document).on('mouseout', '.card', function (e) {
  $(this).closest('.card').removeClass('hover');
});
/* End Leadership Page */

$(document).on('mouseover', '.link-advisory-item', function (e) {
  $(this).closest('.advisory-item').addClass('hover');
});
$(document).on('mouseout', '.link-advisory-item', function (e) {
  $(this).closest('.advisory-item').removeClass('hover');
});
$(document).on('click', '.link-advisory-item', function (e) {
  e.preventDefault();
  $('.advisory-item').removeClass('active');
  $('.advisory-item').find('.custom-arrow').removeClass('up').addClass('down');
  $('.advisory-item').find('img').addClass('img-grayscale');
  $(this).closest('.advisory-item').addClass('active');
  $(this).find('.custom-arrow').removeClass('down').addClass('up');
  $('.advisory-detials-box').hide();
  $('#' + $(this).data('targetdetails')).show();
});
$(window).scroll(function () {
  var cur_top = $(window).scrollTop();
  var trigger_height = $(window).height() / 4 * 3 - 75;
  $('.animated-section').each(function (index) {
    if ($(this).offset().top - cur_top <= trigger_height) {
      $('#' + $(this).attr('id')).css('opacity', 1);
      $('#' + $(this).attr('id')).addClass('animate');
      $('#' + $(this).attr('id') + ' .img-container .anim-limiter div').removeClass('anim-elem-container');
      $('#' + $(this).attr('id') + ' .img-container .anim-limiter div img').removeClass('anim-inner-elem');
      $('#' + $(this).attr('id') + ' .img-container .anim-limiter div').addClass('anim-elem-box');
      $('#' + $(this).attr('id') + ' .img-container .anim-limiter div img').addClass('anim-active');
    } else {
      $('#' + $(this).attr('id')).css('opacity', 0);
      $('#' + $(this).attr('id')).removeClass('animate');
      $('#' + $(this).attr('id') + ' .img-container .anim-limiter div').addClass('anim-elem-container');
      $('#' + $(this).attr('id') + ' .img-container .anim-limiter div img').addClass('anim-inner-elem');
      $('#' + $(this).attr('id') + ' .img-container .anim-limiter div').removeClass('anim-elem-box');
      $('#' + $(this).attr('id') + ' .img-container .anim-limiter div img').removeClass('anim-active');
    }
  });

  if ($("#our-core").length > 0 && cur_top >= $('#our-core').offset().top - 600) {
    $('#our-core').css('opacity', 1);
    $('#our-core .content-with-icon').addClass('animate');
  } else {
    $('#our-core').css('opacity', 0);
    $('#our-core .content-with-icon').removeClass('animate');
  }
});